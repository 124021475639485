<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Privacy Policy', group: 'Onetrust', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesOnetrustPrivacyPolicy.jpg',
      description: 'One Trust Privacy Policy',
      tags: ['GDPR', 'Vendor'],
      fields: [],
      templates: [{ label: 'Privacy Policy', fields: [] }],
    })
  },
}
</script>

<template>
  <div class="container font-sans2 py-16">
    <div class="w-full p-12 bg-white">
      <!-- Container in which the privacy notice will be rendered -->
      <div id="otnotice-052bcd62-6e08-47c9-9723-695a102136ae" class="otnotice" />
    </div>
  </div>
</template>
