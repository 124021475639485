<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      showGallery: false,
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    imageStyles() {
      const styles = { minHeight: '400px' }

      if (this.fields.gallery.value && this.fields.gallery.value.length > 0)
        styles.backgroundImage = `url(${this.fields.gallery.value[0].url})`

      return styles
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Featured Bed Type', group: 'Rooms', layouts: ['AtlantisMobileApp', 'Atlantisbahamas'] },
      preview: 'SlicesGlueStayFeaturedBedType.jpg',
      description: 'Featured Bed type to be placed inside featured bed types',
      tags: ['Room', 'Bed Type', 'Hotel', 'Stay'],

      fields: {
        gallery: { label: 'gallery', type: 'files' },
        title: { label: 'title', type: 'text' },
        copy: { label: 'copy', type: 'text' },
        link: { label: 'link', type: 'link' },
        viewsList: { label: 'viewsList', type: 'text' },
        bedsList: { label: 'bedsList', type: 'text' },
        maxAdults: { label: 'maxAdults', type: 'text' },
        maxChildren: { label: 'maxChildren', type: 'text' },
        minFt: { label: 'minFt', type: 'text' },
        maxFt: { label: 'maxFt', type: 'text' },
        tags: { label: 'tags', type: 'text' },
      },
      templates: [{
        label: 'Featured Bed Type',
        fields: {
          gallery: { value: [] },
          title: { value: 'Title' },
          copy: { value: 'Copy' },
          link: { value: { text: 'Link', href: '' } },
          viewsList: { value: 'Views List' },
          bedsList: { value: 'Beds List' },
          maxAdults: { value: '1' },
          maxChildren: { value: '4' },
          minFt: { value: '100' },
          maxFt: { value: '200' },
          tags: { value: [] },
        },
      }],
    })
  },
  methods: {
    feetToMeters(feet) {
      return Math.round(feet * 0.3048)
    },
    pushToDataLayer() {
      if (window.dataLayer)
        window.dataLayer.push({ suiteName: this.fields.title.value })
    },
  },
}
</script>

<template>
  <div class="relative w-full">
    <div class="flex flex-col lg:flex-row bg-white">
      <div class="flex-1 bg-cover bg-center relative" :style="imageStyles">
        <button
          class="text-white absolute bottom-0 right-0 mr-5 mb-5"
          @click="showGallery = true"
        >
          <IconsPlusButton style="width: 22px; height: 22px" />
        </button>
        <GlueGalleriesModalGallery
          :show="showGallery"
          :gallery="fields.gallery.value"
          :title="fields.title.value"
          @close="showGallery = false"
        />
      </div>
      <div class="bg-white hotel-theme-bg flex flex-col w-full lg:w-1/3 px-8">
        <div class="headline-3 pt-8 pb-5 hotel-theme-fg">
          {{ fields.title.value }}
        </div>
        <div
          class="body text-gray-500 hotel-theme-fg border-b border-gray-400 hotel-theme-border pb-6"
        >
          <span v-html="fields.copy.value" />
          <a v-if="fields.link.value" class="block underline mt-2" :href="fields.link.value.href">Learn More</a>
        </div>
        <div class="flex flex-col justify-between h-full pb-8">
          <div class="mb-8 flex h-full">
            <div class="flex-1 pt-8">
              <div
                class="text-xs text-zinc-600 hotel-theme-fg font-sans2 tracking-wider mb-10"
              >
                <div class="mb-3 flex items-center">
                  <div
                    class="mr-2 text-orange-800 hotel-theme-fg flex justify-center"
                    style="width: 16px"
                  >
                    <GlueSvgsStayEye width="16" height="11" />
                  </div>
                  {{ fields.viewsList.value }}
                </div>
                <div v-if="fields.bedsList.value" class="mb-3 flex items-center">
                  <div
                    class="mr-2 text-orange-800 hotel-theme-fg flex justify-center"
                    style="width: 16px"
                  >
                    <GlueSvgsStayBed width="17" height="14" />
                  </div>
                  {{ fields.bedsList.value }}
                </div>
                <div class="mb-3 flex items-center">
                  <div
                    class="mr-2 text-orange-800 hotel-theme-fg flex justify-center"
                    style="width: 16px"
                  >
                    <GlueSvgsStayPerson width="16" height="11" />
                  </div>
                  {{ fields.maxAdults.value }} Adults<template
                    v-if="fields.maxChildren.value"
                  >
                    , {{ fields.maxChildren.value }} Children
                  </template>
                </div>
                <div class="mb-3 flex items-center">
                  <div
                    class="mr-2 text-orange-800 hotel-theme-fg flex justify-center"
                    style="width: 16px"
                  >
                    <GlueSvgsStayFloorPlan width="11" height="12" />
                  </div>
                  {{ fields.minFt.value }} - {{ fields.maxFt.value }} Sq. Ft. |
                  {{ feetToMeters(fields.minFt.value) }} -
                  {{ feetToMeters(fields.maxFt.value) }} Sq. M.
                </div>
              </div>
              <div class="-ml-1">
                <div
                  v-for="(tag, key) in fields.tags.value"
                  :key="key"
                  class="bg-zinc-300 float-left m-1 py-2 px-5 text-zinc-600 font-sans2 text-xs rounded-lg"
                >
                  {{ tag }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <BookingBookNow
              :dropdown="false"
              :location="`Featured Bed Type - ${fields.title.value}`"
            >
              <button
                class="glue-btn btn-ghost border-zinc-600 hotel-theme-border hotel-theme-fg text-xs tracking-widest p-3 pt-5 px-16 text-zinc-700 w-full"
                @click="pushToDataLayer"
              >
                Book Now
              </button>
            </BookingBookNow>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
